import { default as bankfsYlek9Yf0Meta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/bank.vue?macro=true";
import { default as bankaktR4pUhLjMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardf0J9MF1OgdMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/card.vue?macro=true";
import { default as codepay5Ce9cJ3kXkMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2WS0wYaLYI3Meta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptofflnRZpMZWMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletD5Glgd33idMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/ewallet.vue?macro=true";
import { default as betQe5fUY9SjFMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/history/bet.vue?macro=true";
import { default as transaction30CYLZr9cBMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/history/transaction.vue?macro=true";
import { default as historyoiRwT4pET1Meta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/history.vue?macro=true";
import { default as profileywLryXNrx7Meta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/profile.vue?macro=true";
import { default as trading_45p2pnTA6H7fVjmMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/trading-p2p.vue?macro=true";
import { default as bankXZOWLv1UWkMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/withdraw/bank.vue?macro=true";
import { default as cardAWe2Xs3N4FMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/withdraw/card.vue?macro=true";
import { default as cryptoijSiPppR2OMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountVGqPYWK1qvMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account.vue?macro=true";
import { default as base_45buttonn3I6DmWnDKMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downTTeGrWcuZCMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radiohnwGGfgiVwMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgMbVGaoGgS4Meta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputOgN7TyRS76Meta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingjs98dMyVxnMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalhls8KMF2yBMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputd69LD3Pa9YMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationhkVZ9qSZgzMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankWoo39dB3YaMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemQBFulyA3GoMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/game-item.vue?macro=true";
import { default as indexI8NUUbuUlNMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/index.vue?macro=true";
import { default as _91type_93tMeBYwUccfMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/game/[type].vue?macro=true";
import { default as gameV6JzzXS83qMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/game.vue?macro=true";
import { default as _91alias_93m4fHx2L9HQMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieuwXgidLkJjzMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93w77taTQpeeMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45dan8wCBKTfdFVMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/huong-dan.vue?macro=true";
import { default as indexbTfpew04MVMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/index.vue?macro=true";
import { default as _91id_930lHx6vTr8jMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as index7QO42Axo7KMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93p2FP3nJRmHMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipKsbqBtg58oMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45maiqzmNkViJdEMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93LY21Uwy5mrMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/livecasino/[type].vue?macro=true";
import { default as livecasinoGc77DNWfIsMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/livecasino.vue?macro=true";
import { default as reset_45passwordGpKkXz1DXYMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/reset-password.vue?macro=true";
import { default as _91id_93tFs0ue1v9UMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/the-thao/[id].vue?macro=true";
import { default as index0vGVJZ6VV6Meta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/the-thao/index.vue?macro=true";
import { default as indexiMO1Fha8fwMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93eGBj7uMWZfMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as index4r95ZU3YxqMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93Wu80TBdXIwMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tucnt5vpNXqxxMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailx5llIRDVXbMeta } from "/Users/yoshi/Documents/Project/Brand/ps23-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountVGqPYWK1qvMeta?.name ?? "account",
    path: accountVGqPYWK1qvMeta?.path ?? "/account",
    children: [
  {
    name: bankfsYlek9Yf0Meta?.name ?? "account-bank",
    path: bankfsYlek9Yf0Meta?.path ?? "bank",
    meta: bankfsYlek9Yf0Meta || {},
    alias: bankfsYlek9Yf0Meta?.alias || [],
    redirect: bankfsYlek9Yf0Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bankaktR4pUhLjMeta?.name ?? "account-deposit-bank",
    path: bankaktR4pUhLjMeta?.path ?? "deposit/bank",
    meta: bankaktR4pUhLjMeta || {},
    alias: bankaktR4pUhLjMeta?.alias || [],
    redirect: bankaktR4pUhLjMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardf0J9MF1OgdMeta?.name ?? "account-deposit-card",
    path: cardf0J9MF1OgdMeta?.path ?? "deposit/card",
    meta: cardf0J9MF1OgdMeta || {},
    alias: cardf0J9MF1OgdMeta?.alias || [],
    redirect: cardf0J9MF1OgdMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepay5Ce9cJ3kXkMeta?.name ?? "account-deposit-codepay",
    path: codepay5Ce9cJ3kXkMeta?.path ?? "deposit/codepay",
    meta: codepay5Ce9cJ3kXkMeta || {},
    alias: codepay5Ce9cJ3kXkMeta?.alias || [],
    redirect: codepay5Ce9cJ3kXkMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2WS0wYaLYI3Meta?.name ?? "account-deposit-codepay2",
    path: codepay2WS0wYaLYI3Meta?.path ?? "deposit/codepay2",
    meta: codepay2WS0wYaLYI3Meta || {},
    alias: codepay2WS0wYaLYI3Meta?.alias || [],
    redirect: codepay2WS0wYaLYI3Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptofflnRZpMZWMeta?.name ?? "account-deposit-crypto",
    path: cryptofflnRZpMZWMeta?.path ?? "deposit/crypto",
    meta: cryptofflnRZpMZWMeta || {},
    alias: cryptofflnRZpMZWMeta?.alias || [],
    redirect: cryptofflnRZpMZWMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletD5Glgd33idMeta?.name ?? "account-deposit-ewallet",
    path: ewalletD5Glgd33idMeta?.path ?? "deposit/ewallet",
    meta: ewalletD5Glgd33idMeta || {},
    alias: ewalletD5Glgd33idMeta?.alias || [],
    redirect: ewalletD5Glgd33idMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  },
  {
    name: historyoiRwT4pET1Meta?.name ?? "account-history",
    path: historyoiRwT4pET1Meta?.path ?? "history",
    children: [
  {
    name: betQe5fUY9SjFMeta?.name ?? "account-history-bet",
    path: betQe5fUY9SjFMeta?.path ?? "bet",
    meta: betQe5fUY9SjFMeta || {},
    alias: betQe5fUY9SjFMeta?.alias || [],
    redirect: betQe5fUY9SjFMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transaction30CYLZr9cBMeta?.name ?? "account-history-transaction",
    path: transaction30CYLZr9cBMeta?.path ?? "transaction",
    meta: transaction30CYLZr9cBMeta || {},
    alias: transaction30CYLZr9cBMeta?.alias || [],
    redirect: transaction30CYLZr9cBMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyoiRwT4pET1Meta || {},
    alias: historyoiRwT4pET1Meta?.alias || [],
    redirect: historyoiRwT4pET1Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profileywLryXNrx7Meta?.name ?? "account-profile",
    path: profileywLryXNrx7Meta?.path ?? "profile",
    meta: profileywLryXNrx7Meta || {},
    alias: profileywLryXNrx7Meta?.alias || [],
    redirect: profileywLryXNrx7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2pnTA6H7fVjmMeta?.name ?? "account-trading-p2p",
    path: trading_45p2pnTA6H7fVjmMeta?.path ?? "trading-p2p",
    meta: trading_45p2pnTA6H7fVjmMeta || {},
    alias: trading_45p2pnTA6H7fVjmMeta?.alias || [],
    redirect: trading_45p2pnTA6H7fVjmMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bankXZOWLv1UWkMeta?.name ?? "account-withdraw-bank",
    path: bankXZOWLv1UWkMeta?.path ?? "withdraw/bank",
    meta: bankXZOWLv1UWkMeta || {},
    alias: bankXZOWLv1UWkMeta?.alias || [],
    redirect: bankXZOWLv1UWkMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardAWe2Xs3N4FMeta?.name ?? "account-withdraw-card",
    path: cardAWe2Xs3N4FMeta?.path ?? "withdraw/card",
    meta: cardAWe2Xs3N4FMeta || {},
    alias: cardAWe2Xs3N4FMeta?.alias || [],
    redirect: cardAWe2Xs3N4FMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptoijSiPppR2OMeta?.name ?? "account-withdraw-crypto",
    path: cryptoijSiPppR2OMeta?.path ?? "withdraw/crypto",
    meta: cryptoijSiPppR2OMeta || {},
    alias: cryptoijSiPppR2OMeta?.alias || [],
    redirect: cryptoijSiPppR2OMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountVGqPYWK1qvMeta || {},
    alias: accountVGqPYWK1qvMeta?.alias || [],
    redirect: accountVGqPYWK1qvMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonn3I6DmWnDKMeta?.name ?? "dev-base-button",
    path: base_45buttonn3I6DmWnDKMeta?.path ?? "/dev/base-button",
    meta: base_45buttonn3I6DmWnDKMeta || {},
    alias: base_45buttonn3I6DmWnDKMeta?.alias || [],
    redirect: base_45buttonn3I6DmWnDKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downTTeGrWcuZCMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downTTeGrWcuZCMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downTTeGrWcuZCMeta || {},
    alias: base_45count_45downTTeGrWcuZCMeta?.alias || [],
    redirect: base_45count_45downTTeGrWcuZCMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radiohnwGGfgiVwMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radiohnwGGfgiVwMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radiohnwGGfgiVwMeta || {},
    alias: base_45dropdown_45radiohnwGGfgiVwMeta?.alias || [],
    redirect: base_45dropdown_45radiohnwGGfgiVwMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgMbVGaoGgS4Meta?.name ?? "dev-base-img",
    path: base_45imgMbVGaoGgS4Meta?.path ?? "/dev/base-img",
    meta: base_45imgMbVGaoGgS4Meta || {},
    alias: base_45imgMbVGaoGgS4Meta?.alias || [],
    redirect: base_45imgMbVGaoGgS4Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputOgN7TyRS76Meta?.name ?? "dev-base-input",
    path: base_45inputOgN7TyRS76Meta?.path ?? "/dev/base-input",
    meta: base_45inputOgN7TyRS76Meta || {},
    alias: base_45inputOgN7TyRS76Meta?.alias || [],
    redirect: base_45inputOgN7TyRS76Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingjs98dMyVxnMeta?.name ?? "dev-base-loading",
    path: base_45loadingjs98dMyVxnMeta?.path ?? "/dev/base-loading",
    meta: base_45loadingjs98dMyVxnMeta || {},
    alias: base_45loadingjs98dMyVxnMeta?.alias || [],
    redirect: base_45loadingjs98dMyVxnMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalhls8KMF2yBMeta?.name ?? "dev-base-modal",
    path: base_45modalhls8KMF2yBMeta?.path ?? "/dev/base-modal",
    meta: base_45modalhls8KMF2yBMeta || {},
    alias: base_45modalhls8KMF2yBMeta?.alias || [],
    redirect: base_45modalhls8KMF2yBMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputd69LD3Pa9YMeta?.name ?? "dev-base-money-input",
    path: base_45money_45inputd69LD3Pa9YMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputd69LD3Pa9YMeta || {},
    alias: base_45money_45inputd69LD3Pa9YMeta?.alias || [],
    redirect: base_45money_45inputd69LD3Pa9YMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationhkVZ9qSZgzMeta?.name ?? "dev-base-pagination",
    path: base_45paginationhkVZ9qSZgzMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationhkVZ9qSZgzMeta || {},
    alias: base_45paginationhkVZ9qSZgzMeta?.alias || [],
    redirect: base_45paginationhkVZ9qSZgzMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankWoo39dB3YaMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankWoo39dB3YaMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankWoo39dB3YaMeta || {},
    alias: base_45select_45bankWoo39dB3YaMeta?.alias || [],
    redirect: base_45select_45bankWoo39dB3YaMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemQBFulyA3GoMeta?.name ?? "dev-game-item",
    path: game_45itemQBFulyA3GoMeta?.path ?? "/dev/game-item",
    meta: game_45itemQBFulyA3GoMeta || {},
    alias: game_45itemQBFulyA3GoMeta?.alias || [],
    redirect: game_45itemQBFulyA3GoMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexI8NUUbuUlNMeta?.name ?? "dev",
    path: indexI8NUUbuUlNMeta?.path ?? "/dev",
    meta: indexI8NUUbuUlNMeta || {},
    alias: indexI8NUUbuUlNMeta?.alias || [],
    redirect: indexI8NUUbuUlNMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameV6JzzXS83qMeta?.name ?? "game",
    path: gameV6JzzXS83qMeta?.path ?? "/game",
    children: [
  {
    name: _91type_93tMeBYwUccfMeta?.name ?? "game-type",
    path: _91type_93tMeBYwUccfMeta?.path ?? ":type",
    meta: _91type_93tMeBYwUccfMeta || {},
    alias: _91type_93tMeBYwUccfMeta?.alias || [],
    redirect: _91type_93tMeBYwUccfMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameV6JzzXS83qMeta || {},
    alias: gameV6JzzXS83qMeta?.alias || [],
    redirect: gameV6JzzXS83qMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieuwXgidLkJjzMeta?.name ?? "gioi-thieu",
    path: gioi_45thieuwXgidLkJjzMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93m4fHx2L9HQMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93m4fHx2L9HQMeta?.path ?? ":alias",
    meta: _91alias_93m4fHx2L9HQMeta || {},
    alias: _91alias_93m4fHx2L9HQMeta?.alias || [],
    redirect: _91alias_93m4fHx2L9HQMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieuwXgidLkJjzMeta || {},
    alias: gioi_45thieuwXgidLkJjzMeta?.alias || [],
    redirect: gioi_45thieuwXgidLkJjzMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45dan8wCBKTfdFVMeta?.name ?? "huong-dan",
    path: huong_45dan8wCBKTfdFVMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93w77taTQpeeMeta?.name ?? "huong-dan-alias",
    path: _91alias_93w77taTQpeeMeta?.path ?? ":alias",
    meta: _91alias_93w77taTQpeeMeta || {},
    alias: _91alias_93w77taTQpeeMeta?.alias || [],
    redirect: _91alias_93w77taTQpeeMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45dan8wCBKTfdFVMeta || {},
    alias: huong_45dan8wCBKTfdFVMeta?.alias || [],
    redirect: huong_45dan8wCBKTfdFVMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexbTfpew04MVMeta?.name ?? "index",
    path: indexbTfpew04MVMeta?.path ?? "/",
    meta: indexbTfpew04MVMeta || {},
    alias: indexbTfpew04MVMeta?.alias || [],
    redirect: indexbTfpew04MVMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45maiqzmNkViJdEMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_930lHx6vTr8jMeta?.name ?? "khuyen-mai-id",
    path: _91id_930lHx6vTr8jMeta?.path ?? ":id",
    meta: _91id_930lHx6vTr8jMeta || {},
    alias: _91id_930lHx6vTr8jMeta?.alias || [],
    redirect: _91id_930lHx6vTr8jMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: index7QO42Axo7KMeta?.name ?? "khuyen-mai",
    path: index7QO42Axo7KMeta?.path ?? "",
    meta: index7QO42Axo7KMeta || {},
    alias: index7QO42Axo7KMeta?.alias || [],
    redirect: index7QO42Axo7KMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93p2FP3nJRmHMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93p2FP3nJRmHMeta?.path ?? "the-loai/:id",
    meta: _91id_93p2FP3nJRmHMeta || {},
    alias: _91id_93p2FP3nJRmHMeta?.alias || [],
    redirect: _91id_93p2FP3nJRmHMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipKsbqBtg58oMeta?.name ?? "khuyen-mai-vip",
    path: vipKsbqBtg58oMeta?.path ?? "vip",
    meta: vipKsbqBtg58oMeta || {},
    alias: vipKsbqBtg58oMeta?.alias || [],
    redirect: vipKsbqBtg58oMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45maiqzmNkViJdEMeta?.name ?? undefined,
    meta: khuyen_45maiqzmNkViJdEMeta || {},
    alias: khuyen_45maiqzmNkViJdEMeta?.alias || [],
    redirect: khuyen_45maiqzmNkViJdEMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasinoGc77DNWfIsMeta?.name ?? "livecasino",
    path: livecasinoGc77DNWfIsMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93LY21Uwy5mrMeta?.name ?? "livecasino-type",
    path: _91type_93LY21Uwy5mrMeta?.path ?? ":type",
    meta: _91type_93LY21Uwy5mrMeta || {},
    alias: _91type_93LY21Uwy5mrMeta?.alias || [],
    redirect: _91type_93LY21Uwy5mrMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasinoGc77DNWfIsMeta || {},
    alias: livecasinoGc77DNWfIsMeta?.alias || [],
    redirect: livecasinoGc77DNWfIsMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordGpKkXz1DXYMeta?.name ?? "reset-password",
    path: reset_45passwordGpKkXz1DXYMeta?.path ?? "/reset-password",
    meta: reset_45passwordGpKkXz1DXYMeta || {},
    alias: reset_45passwordGpKkXz1DXYMeta?.alias || [],
    redirect: reset_45passwordGpKkXz1DXYMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tFs0ue1v9UMeta?.name ?? "the-thao-id",
    path: _91id_93tFs0ue1v9UMeta?.path ?? "/the-thao/:id",
    meta: _91id_93tFs0ue1v9UMeta || {},
    alias: _91id_93tFs0ue1v9UMeta?.alias || [],
    redirect: _91id_93tFs0ue1v9UMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: index0vGVJZ6VV6Meta?.name ?? "the-thao",
    path: index0vGVJZ6VV6Meta?.path ?? "/the-thao",
    meta: index0vGVJZ6VV6Meta || {},
    alias: index0vGVJZ6VV6Meta?.alias || [],
    redirect: index0vGVJZ6VV6Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexiMO1Fha8fwMeta?.name ?? "the-thao-lich-thi-dau",
    path: indexiMO1Fha8fwMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexiMO1Fha8fwMeta || {},
    alias: indexiMO1Fha8fwMeta?.alias || [],
    redirect: indexiMO1Fha8fwMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tucnt5vpNXqxxMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_93eGBj7uMWZfMeta?.name ?? "tin-tuc-alias",
    path: _91alias_93eGBj7uMWZfMeta?.path ?? ":alias",
    meta: _91alias_93eGBj7uMWZfMeta || {},
    alias: _91alias_93eGBj7uMWZfMeta?.alias || [],
    redirect: _91alias_93eGBj7uMWZfMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: index4r95ZU3YxqMeta?.name ?? "tin-tuc",
    path: index4r95ZU3YxqMeta?.path ?? "",
    meta: index4r95ZU3YxqMeta || {},
    alias: index4r95ZU3YxqMeta?.alias || [],
    redirect: index4r95ZU3YxqMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93Wu80TBdXIwMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93Wu80TBdXIwMeta?.path ?? "the-loai/:Slug",
    meta: _91Slug_93Wu80TBdXIwMeta || {},
    alias: _91Slug_93Wu80TBdXIwMeta?.alias || [],
    redirect: _91Slug_93Wu80TBdXIwMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tucnt5vpNXqxxMeta?.name ?? undefined,
    meta: tin_45tucnt5vpNXqxxMeta || {},
    alias: tin_45tucnt5vpNXqxxMeta?.alias || [],
    redirect: tin_45tucnt5vpNXqxxMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailx5llIRDVXbMeta?.name ?? "verify-mail",
    path: verify_45mailx5llIRDVXbMeta?.path ?? "/verify-mail",
    meta: verify_45mailx5llIRDVXbMeta || {},
    alias: verify_45mailx5llIRDVXbMeta?.alias || [],
    redirect: verify_45mailx5llIRDVXbMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps23-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]